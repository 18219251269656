import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoIdentityCredentials, WebIdentityCredentials } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk'

const REGION = process.env.REACT_APP_COGNITO_REGION
export const USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID
const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID


AWS.config.update({
	region: REGION
})
const userData = {
    UserPoolId : USER_POOL_ID,
    ClientId : CLIENT_ID
}
export const userPool = new CognitoUserPool(userData);
export function signUpUser(email, password){
 const p = new Promise((res, rej)=>{  
 const attributeList = []  
 const dataEmail = {
      Name : 'email',
      Value : email
  }
  userPool.signUp(email, password, [], null, 
  				  function(err, result){
      if (err) {
        console.log("Userpool signup error "+ err.message)
          rej(err)
          return
      }
      console.log("Userpool signup result "+ result)
      res(result)
  })
 })
 return p
}

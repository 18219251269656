import axios from 'axios';


const axiosClient = axios.create();
let baseURL = ''
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:8080/v1';
    axiosClient.defaults.baseURL = 'http://localhost:8080/v1/';
    axiosClient.defaults.headers = {
      'id_token': localStorage.getItem('id_token'),
      'Content-Type' :'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PATCH',
      'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept' ,
      'Access-Control-Expose-Headers': 'correlationid'
    };    
} else {
    baseURL = 'https://api.supportspoc.com/v1';
    axiosClient.defaults.baseURL = 'https://api.supportspoc.com/v1';
    axiosClient.defaults.headers = {
      'id_token': localStorage.getItem('id_token'),
      'Content-Type' :'application/json',
      'Access-Control-Allow-Origin': 'https://app.supportspoc.com',
      
      'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PATCH',
      'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept' ,
      'Access-Control-Expose-Headers': 'correlationid'
    };        
}
axiosClient.defaults.timeout = 10000;

axiosClient.defaults.withCredentials = true;
export function postRequest(URL, payload, headers) {
  return axiosClient.post(baseURL+`/${URL}`, payload, headers).then(response => response);
}
axiosClient.interceptors.request.use(function (request) {
  return request;
}, null, { synchronous: true });
export default axiosClient;
import SignUp from './components/SignUp'
import {useState, setState, useRef, useEffect} from 'react';

import './App.css';

function App() {

function sendMessage(){

}
  return (
    <div className="App">
      
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
          <a class="navbar-brand" href="#"><img src={require('./logo.png')} /></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul class="navbar-nav mb-2 mb-lg-0 d-flex">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item"><a class="nav-link" href="https://docs.supportspoc.com">Docs</a></li>
            <li class="nav-item">
              <a class="nav-link" href="#products">Products</a>
            </li>            
            <li class="nav-item"><a class="nav-link" href="#features">Features </a></li>
            <li class="nav-item"><a class="nav-link" href="#about">Who We Are</a></li>
             <li class="nav-item"><a class="nav-link" href="#pricing">Pricing</a></li>
            <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
            <li class="nav-item"><a class="nav-link" href="https://app.supportspoc.com/login">Login</a></li>
          </ul>
        </div>


      </div>
    </nav>
      

{/* <!------------Section-1 Start----------------> */}
<section class="section_1 mt_50">
<div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 text-center text-lg-start left_side" style={{color:"#5b3e31"}}>
<h1>Single Point <br/> Of Contact </h1><h2> for all support needs</h2>
<p>SupportSPOC aims at establishing a Single Point of Contact for all support solutions</p>
      </div>
      <div class="mx-auto col-lg-5 right_side">
      <SignUp />

      </div>
    </div>
  </div>    
</section>
{/*<!------------Section-1 End----------------> */}
  
 {/* <!-- Carousel Start -->  */}
  <div id="products" class="carousel carousel-dark slide mt-3 " data-bs-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
             <div class="card-wrapper container-sm d-flex  justify-content-around">
                  <div class="card" style={{margin: '5px'}}>
                      <div class="card-content">
                             <span class="image-content icon">
                                  <i class="las la-briefcase"></i>
                              </span>                          
                              <h2 class="name">Ticketing System</h2>
                              <p class="description">A SAAS ticketing system is a cloud-based application that provides ticketing functionality as a service, allowing users to access and utilize the software over the internet on a subscription basis</p>
                      </div>
                </div>
                <div class="card" style={{margin: '5px'}}>
                      <div class="card-content">
                             <span class="image-content icon">
                                  <i class="las la-briefcase"></i>
                              </span>                          
                            <h2 class="name">Colud Telephony</h2>
                            <p class="description">Live calls from a browser via SaaS on the cloud enable real-time audio and video communication directly through web applications without the need for additional software installations.</p>
                      </div>
                </div>              
                <div class="card" style={{margin: '5px'}}>
                      <div class="card-content">
                             <span class="image-content icon">
                                  <i class="las la-briefcase"></i>
                              </span>                          
                            <h2 class="name">Chat(Upcoming)</h2>
                            <p class="description">A chat support system is a web-based application that facilitates real-time communication between users and support representatives, typically integrated into websites for customer assistance.</p>
                      </div>
                </div>                   
             </div>
    </div>
    <div class="carousel-item">
         <div class="card-wrapper container-sm d-flex   justify-content-around">
                <div class="card" style={{margin: '5px'}}>
                      <div class="card-content">
                             <span class="image-content icon">
                                  <i class="las la-briefcase"></i>
                              </span>                          
                              <h2 class="name">Ticketing System</h2>
                              <p class="description">A SAAS ticketing system is a cloud-based application that provides ticketing functionality as a service, allowing users to access and utilize the software over the internet on a subscription basis</p>
                      </div>
                </div>   
                <div class="card" style={{margin: '5px'}}>
                      <div class="card-content">
                             <span class="image-content icon">
                                  <i class="las la-briefcase"></i>
                              </span>                          
                            <h2 class="name">Cloud Telephony</h2>
                            <p class="description">Live calls from a browser via SaaS on the cloud enable real-time audio and video communication directly through web applications without the need for additional software installations.</p>
                      </div>
                </div>   
                <div class="card" style={{margin: '5px'}}>
                      <div class="card-content">
                             <span class="image-content icon">
                                  <i class="las la-briefcase"></i>
                              </span>                          
                            <h2 class="name">Chat(Upcoming)</h2>
                            <p class="description">A chat support system is a web-based application that facilitates real-time communication between users and support representatives, typically integrated into websites for customer assistance.</p>
                      </div>
                </div>            
         </div>
      </div>

      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
   </div>
  </div>
  {/* <!-- Carousel End --> */}




{/*  <!-- Services --> */}
<section id="features" class="services mt_80">
     {/* <!--  Section Title --> */}
      <div class="container section-title aos-init aos-animate" data-aos="fade-up">
        <h2>Features</h2>
        <p>Available Features</p>
      </div>
     {/* <!-- End Section Title --> */}

      <div class="container">

        <div class="row gy-4">

         <div class="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="service-item d-flex">
              <div class="icon flex-shrink-0"><i class="las la-business-time"></i></div>
              <div>
                <h4 class="title"><a href="#" class="stretched-link">Ticket Mangement</a></h4>
                <p class="description">Ticket management refers to the process of handling and resolving customer inquiries, issues, or requests systematically through a centralized system. This typically involves the creation, assignment, tracking, and resolution of tickets or support tickets, which contain details about the customer's concern, along with any associated actions taken to address it. </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="service-item d-flex">
              <div class="icon flex-shrink-0"><i class="las la-business-time"></i></div>
              <div>
                <h4 class="title"><a href="#" class="stretched-link">Contact Management</a></h4>
                <p class="description">Contact management involves organizing and maintaining information about our customers. </p>
              </div>
            </div>
          </div>
         {/* <!-- End Service Item --> */}

          <div class="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <div class="service-item d-flex">
              <div class="icon flex-shrink-0"><i class="las la-business-time"></i></div>
              <div>
                <h4 class="title"><a href="#" class="stretched-link">Business Hours</a></h4>
                <p class="description">Business hours refer to the specific hours during which a business is open to conduct its operations and serve customers. These hours typically vary depending on factors such as industry, location, and target audience, with many businesses operating during standard weekday hours and some extending to weekends or offering 24/7 services</p>
              </div>
            </div>
          </div>
        {/*  <!-- End Service Item --> */}

          <div class="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
            <div class="service-item d-flex">
              <div class="icon flex-shrink-0"><i class="las la-business-time"></i></div>
              <div>
                <h4 class="title"><a href="#" class="stretched-link">SLA Management</a></h4>
                <p class="description">SLA (Service Level Agreement) management involves the process of defining, negotiating, and monitoring the agreed-upon levels of service between a service provider and its customers. This includes specifying key performance indicators like response times, resolution times, and other metrics</p>
              </div>
            </div>
          </div> 
          {/*  <!-- End Service Item --> */}
          <div class="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
            <div class="service-item d-flex">
              <div class="icon flex-shrink-0"><i class="las la-business-time"></i></div>
              <div>
                <h4 class="title"><a href="#" class="stretched-link">Cloud Telephony</a></h4>
                <p class="description">Cloud telephony allows to make and receive phone calls over the internet instead of through traditional phone lines. Users can make and receive phone calls directly through their web browser without needing a physical phone</p>
              </div>
            </div>                  
          </div>     
          {/*  <!-- End Service Item --> */}   
          <div class="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
            <div class="service-item d-flex">
              <div class="icon flex-shrink-0"><i class="las la-business-time"></i></div>
              <div>
                <h4 class="title"><a href="#" class="stretched-link">IVR</a></h4>
                <p class="description">Interactive Voice Response (IVR) enable callers to navigate a phone system's menu by responding to voice prompts with their voice or by pressing keys on their phone's keypad.</p>
              </div>
            </div>                  
          </div>     
          {/*  <!-- End Service Item --> */} 
        </div>        
      </div>
    </section> 
 {/* <!-- End of Services --> */}


{/*  <!-- Start of About Us -->  */}
    <section id="about" class="about mt_80 py-3" >
      <div class="container section-title aos-init aos-animate " data-aos="fade-up">
        <h2>Who We Are</h2>
        <p>Welcome to SupportSPOC: Your Support Solutions Partner</p>
        <p style= {{lineHeight: "2"}} class="text-center">SupportSPOC is a cost-effective initiative aimed at providing comprehensive support solutions to businesses, particularly MSMEs. By offering services at a significantly reduced price point, it enables companies to slash their support costs without compromising on quality. Tailored to the needs of smaller enterprises, SupportSPOC ensures efficient service delivery and includes value-added features such as analytics tools. Ultimately, SupportSPOC empowers businesses to better serve their customers while allocating resources to other critical areas of operation.</p>
      </div>       
    </section>

{/*  <!----Price Plan Started--------->  */}
<section id="pricing" class="mt_80">
        <div class="section-title">
          <h2>Pricing Plan</h2>
          <p>Plan <strong>Free</strong> for the first 15 days. </p>
        </div>
    <div class="d-flex justify-content-center">
     <div class="pricing-plans">
      <div class="pricing-card standard">
        <div class="heading">
          <h4>STANDARD</h4>
        </div>
        <p class="price">
          <sup>$</sup>6
          <sub>/Month/Agent</sub>          
        </p>
        <ul class="features">
          <li>
            <i class="las la-check"></i>
            Ticket Creation
          </li>
          <li>
            <i class="las la-check"></i>
            Receiving/Making calls from Browser
          </li>
          <li>
            <i class="las la-check"></i>
            Conditional Call Routing
          </li>
          <li>
            <i class="las la-check"></i>
            Interactive Voice Response(IVR)
          </li>
          <li>
            <i class="las la-check"></i>
            Wait Queues
          </li>
          <li>
            <i class="las la-check"></i>
           Contact Management
          </li>
          <li>
            <i class="las la-check"></i>
            Business Hours
          </li>
          <li>
            <i class="las la-check"></i>
            SLA
          </li>
          <li>
            <i class="las la-check"></i>
            Private Comments
          </li>
          <li>
            <i class="las la-check"></i>
            Merge Tickets
          </li>
        </ul>
        <button class="cta-btn" onClick={()=>window.scrollTo(0,0)}>Choose Plan</button>
      </div>
     </div>       
    </div>
  </section>

  

  {/* <!----Contact Us Started---------> */}
  <section id="contact" class="contact mt_80 pb-5">

      <div class="container aos-init aos-animate" data-aos="fade-up">
 
        <div class="section-title">
          <h2>Contact Us</h2>
          <p>Contact </p>
        </div>

        <div class="row gy-4">

          <div class="col-lg-6">

            <div class="row gy-4">
              <div class="col-md-6">
                <div class="info-box">
                  <i class="las la-map-marker-alt"></i>
                  <h3>Address</h3>
                  <p>Hyderabad,<br/>Telangana, India 500062</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="las la-envelope"></i>
                  <h3>Email Us</h3>
                  <p><a href="mailto:support@supportspoc.com">support@supportspoc.com</a></p>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-6">
            <form onSubmit={sendMessage}>
              <div class="row gy-4">

                <div class="col-md-6">
                  <input type="text" name="name" class="form-control" placeholder="Your Name" required="" />
                </div>

                <div class="col-md-6 ">
                  <input type="email" class="form-control" name="email" placeholder="Your Email" required="" />
                </div>

                <div class="col-md-12">
                  <input type="text" class="form-control" name="subject" placeholder="Subject" required="" />
                </div>

                <div class="col-md-12">
                  <textarea class="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                </div>

                <div class="col-md-12 text-center">
                  <div class="error-message"></div>
                  <div class="sent-message"></div>
                  <button type="submit" class="btn btn-primary">Send Message</button>
                </div>

              </div>
            </form>

          </div>

        </div>

      </div>

    </section>
    </div>
  );
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import TAndC from './components/TAndC';
import PrivacyPolicy from './components/PrivacyPolicy';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(    
    <React.StrictMode>    
    <BrowserRouter>
        <Routes>
            <Route path='/terms_conditions' element={<TAndC />} />
            <Route path='/privacy_policy' element={<PrivacyPolicy />} />
            <Route path="/*" element={<App />} />
        </Routes>
    </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

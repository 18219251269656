import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import axiosClient from '../js/axiosClient';
import * as awsProfile from '../js/awsProfile.js'
import TAndC from './TAndC'

function SignUp(props){	
	const errorRef=useRef(null)
	const spinnerRef = useRef(null)
	const toastRef = useRef(null)
	const [email, setEmail] = useState(null)
	const emailRef=useRef(null)
	const [showVerificationCode, setShowVerificationCode] = useState(false)
	useEffect(() => {
		toastRef.current.style.display = 'none'
		spinnerRef.current.style.display = 'none'		
		errorRef.current.style.display = 'none'
	})

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*</span>&nbsp;&nbsp;"+msg
  errorRef.current.style.display='block'
  spinnerRef.current.style.display = 'none'
}

function hideToastAndRedirectToLogin(){
	toastRef.current.innerHTML = ''	
	toastRef.current.style.display = 'none'
	redirectToLogin()
}
function displayToast(msg){
	toastRef.current.innerHTML = msg
	toastRef.current.style.display = 'block'

}
function createAccount(payload){
	axiosClient.post('accounts',payload)
	.then(function (response) {	
		spinnerRef.current.style.display = 'none'	
		toastRef.current.innerHTML = 'A verification link has been sent to your email. Please verify your email id.'
		toastRef.current.style.display = 'block'
		spinnerRef.current.style.display = 'none'
		setTimeout(hideToastAndRedirectToLogin,5000)
	}).catch(function (error) {
		console.log('Signup error '+error)
		spinnerRef.current.style.display = 'none'		
		if(error.response.data === undefined){
			console.log(error.response)
			displayError("Unexpected error")
		}else{			
	    	displayError(error.response.data.error)
		}		
	});
}
	function handleSubmit(event){
		console.log('handleSubmit')
		errorRef.current.style.display = 'none'
		spinnerRef.current.style.display = 'inline-block'		
		event.preventDefault();
		let payload = {'email': event.target.email.value,
					   'fullName' : event.target.fullName.value,
					   'companyName': event.target.companyName.value,
					   'mobileNumber': event.target.mobile.value
					}
		// if(event.target.password.value !== event.target.cpassword.value){
		// 	displayError('Passwords does not match')
		// 	return
		// }
		spinnerRef.current.style.display = 'inline-block'
		  awsProfile.userPool.signUp(event.target.email.value, 
		  	event.target.password.value, [], null, 
		  	function(err, result){
		      if (err) {
		      	spinnerRef.current.style.display = 'none'		
	    		displayError(err.message)
		        return
		      }
		    createAccount(payload)
    	})// awsProfile signup
	}

function redirectToLogin(){
	window.location.href= 'https://app.supportspoc.com/login'
}



	return(
		<div class="signup-container">
            <h3 >Get 14-day free trial</h3>
            <form class="signup-form" onSubmit={handleSubmit}>
                <div class="form-controll name"><input type="text" placeholder="Full Name *" name='fullName' required/></div>
                <div class="form-controll email"><input type="email" placeholder="Email *" name='email'  required/></div>
                <div class="form-controll password"><input type="password" placeholder="Password *" name='password'  required/></div>
                <div class="form-controll phone"> <input type="tel" placeholder="Phone Number *" name='mobile'  required/></div>
                <div class="form-controll company"><input type="text" placeholder="Company Name *" name='companyName' required/></div>                
                
                <div class="agree_chekmark">
                  <label><input type="checkbox" required/> I agree to the <a href="/terms_conditions" >Terms of Service</a> and <a href="/privacy_policy">Privacy Policy</a>.</label></div>
                <button type="submit">SIGN UP FOR FREE</button>
                <span class="spinner-border" role="status" style={{position:'relative',bottom:'45px', color: 'white', height:'35px', width:'35px',zIndex:'100'}} ref={spinnerRef}/>
                {/*<!--div class="social-login">
                    <p>or sign in using</p>
                    <div class="btn_group">
                    <button class="google"><img src="assets/img/google.png" alt="google"></button>
                    <button class="linkedin"><i class="lab la-linkedin-in"></i></button>
                    </div>
                </div --> */}
            </form>
            <div ref={errorRef} style={{textAlign:'left',fontWeight:'500' }}/>
            <div ref={toastRef} style={{height:'40px', display:'flex', justifyContent: 'center', backgroundColor: '#F0F9FA', position:'absolute', top:'10px', left:'0px', width:'100%', fontWeight:'500',fontSize:'18px'}}/>
            

            {/* Terms And Conditions */}
				<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
				  <div class="modal-dialog">
				    <div class="modal-content">
				      <div class="modal-header">
				        <h1 class="modal-title fs-5" id="staticBackdropLabel">Terms & Conditions</h1>
				        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				      </div>
				      <div class="modal-body">
				        <TAndC />
				      </div>
				      <div class="modal-footer">
				        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Understood</button>
				      </div>
				    </div>
				  </div>
				</div>
			{/* End Of Terms And Conditions  */}
        </div>		

		)

}

export default SignUp